<script lang="ts">
	import video from '$lib/home/images/raw-reports.mp4';

	// ffmpeg -i raw-reports.mp4 -r 60 -map v -map_metadata -1 -s 1800x1458 raw-reports-3.mp4 -y
</script>

<div class="mt-12 bg-slate-50 rounded-lg md:p-12 p-8">
	<h2 class="text-3xl font-semibold leading-[1.15] md:w-8/12">
		Or browse the raw reports for detailed analysis.
	</h2>

	<p class="mt-5 text-lg md:w-9/12">
		For the deepest level of analysis, access
		<mark class="font-medium">raw DMARC reports</mark>
		directly, without sacrificing a clean, intuitive interface.
		Ideal for advanced troubleshooting and detailed investigations.
	</p>

	<video class="mt-12 w-full rounded-lg shadow-2xl" autoplay loop muted playsinline>
		<source src={video} type="video/mp4" />
	</video>
</div>
